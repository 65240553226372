<template>
  <div>
         <div
                    class="card p-5 mt-5"
                    style="
            border-radius: 20px;
            box-shadow: 0px 4px 10px rgba(10, 4, 17, 0.15);
          "
                >   
                <div style="min-height:300px;">

                    <div v-if="IsLeadLoading">
                        <LoaderComp/>
                    </div>
                    <div class="row " v-else >
                        <div
                            class="col-12 mb-3 d-flex align-items-center justify-content-between"
                        >
                            <div
                                class="LeadTableInput d-flex align-items-center  pr-3 py-2"
                            >
                                <input
                                    type="text"
                                    placeholder="Search leads"
                                    class=" mx-2 flex-grow-1"
                                    v-model="searchLeads"
                                />
                            </div>

                            <div class="d-flex align-items-center">
                                <button
                                    v-if="selectedLeads.length"
                                    @click="DeleteLeads"
                                    class="btn px-3 py-2"
                                    style="background: rgba(192, 21, 21, 0.2); color: #c01515"
                                >
                                    <span style="font-weight: 500">Delete</span>
                                    <b-icon-trash class="ml-2"></b-icon-trash>
                                </button>

                                <vue-excel-xlsx
                                v-else
        :data="Leads"
        :columns="ExcelColumns"
        :file-name="'LeadData'"
        :file-type="'xlsx'"
        :sheet-name="'sheetname'"
        class="btn px-3 py-2"
                                    style="background: #e0dee2; color: #292929"
        >
        <b-icon-upload class="mr-2"></b-icon-upload>
                                    <span style="font-weight: 500"
                                        >Export</span
                                    >
    </vue-excel-xlsx>
                            </div>
                        </div>
                        <div class="col-12 LeadDataTable">
                            <vue-bootstrap4-table
                                :rows="getLeadsDataTable"
                                :columns="columns"
                                :config="config"
                                @on-select-row="OnRowCheckBoxChanged($event)"
                                @on-all-select-rows="
                                    OnRowCheckBoxChanged($event)
                                "
                                @on-unselect-row="OnRowCheckBoxChanged($event)"
                                @on-all-unselect-rows="
                                    OnRowCheckBoxChanged($event)
                                "
                            >
                                <template slot="action" slot-scope="props">
                                    <button
                                        @click="openSeeResultModal(props.row)"
                                        type="button"
                                        class="btn modal-btn px-2 py-1"
                                        style="
                      background: #f4f3f5;
                      color: #4d1b7e;
                      font-weight: 500;
                    "
                                    >
                                        <b-icon-bag class="mr-2"></b-icon-bag>
                                        See Result
                                    </button>
                                </template>
                                <!-- <template slot="global-search-clear-icon">
       <i class="fa fa-times" aria-hidden="true"></i>
    </template> -->      
    <template slot="name" slot-scope="props">
        <span v-if="props.row.name">
            {{props.row.name}}
        </span>
        <span v-else style="font-weight:700; ">-</span>
    </template>
   
              <template slot="email" slot-scope="props">
        <span v-if="props.row.email">
            {{props.row.email}}
        </span>
        <span v-else style="font-weight:700; ">-</span>
    </template>
              <template slot="phoneNumber" slot-scope="props">
        <span v-if="props.row.phoneNumber">
            {{props.row.phoneNumber}}
        </span>
        <span v-else style="font-weight:700; ">-</span>
    </template>
              <template slot="terms_conditions" slot-scope="props">
        <span v-if="props.row.terms_conditions
" style="font-weight:700; ">
            <b-icon-check-circle class="text-success"></b-icon-check-circle>
        </span>
        <span v-else style="font-weight:700; ">
            <b-icon-x-circle class="text-danger"></b-icon-x-circle>
        </span>
    </template>

                                <template slot="paginataion-previous-button">
                                    <i
                                        class="fa fa-chevron-left"
                                        aria-hidden="true"
                                    ></i>
                                </template>
                                <template slot="paginataion-next-button">
                                    <i
                                        class="fa fa-chevron-right"
                                        aria-hidden="true"
                                    ></i>
                                </template>

                <!-- Sort Icons start-->
                                <template slot="sort-asc-icon">
       <i class="fa fa-sort-asc" aria-hidden="true"></i>
    </template>
    <template slot="sort-desc-icon">
        <i class="fa fa-sort-desc" aria-hidden="true"></i>
    </template>
    <template slot="no-sort-icon">
       <i class="fa fa-sort" aria-hidden="true"></i>
    </template>
     <!-- Sort Icons End-->
                            </vue-bootstrap4-table>

                             <div class="d-flex justify-content-center" style="background:#ffffff" v-if="getLeadsDataTable.length>0">
                <nav aria-label="...">
                  <ul class="pagination ">
                    <li :style="[page.url ? {cursor: 'pointer!important'} : {cursor: 'no-drop!important'}]"
                      :class="[page.active ? 'active' : '', page.url ? '' : 'disabled', 'page-item']"
                      v-for="page in pagination" :key="page.label" @click="movePagination(page.url)">
                      <a class="page-link" v-html="page.label"></a>
                    </li>

                  </ul>
                </nav>
              </div>
                        </div>
                    </div>
                </div>
                    
                </div>

       <!-- See Result Modal -->
        <SeeResultModal ref="SeeResultIndexModal" />
  </div>
</template>

<script>
import { BIconUpload, BIconBag, BIconTrash ,BIconCheckCircle,BIconXCircle} from "bootstrap-vue";
import {mapGetters} from "vuex";
import LoaderComp from "../../customize/component/LoaderComp.vue";
import axios from "axios";
import VueBootstrap4Table from "vue-bootstrap4-table";
import SeeResultModal from "./SeeResultModal.vue";
import globalMixin from "../../../GlobalMixins/globalMixin.js"
export default {
    components: {
        VueBootstrap4Table,
        SeeResultModal,
        BIconUpload,
        BIconBag,
        BIconTrash,
        LoaderComp,
        BIconCheckCircle,
        BIconXCircle
    },
    props:["Token","QuizKey"],
     mixins: [globalMixin],
data(){
    return{
        
        QuizID:'', 
            columns: [
                {
                    label: "Name",
                    name: "name",
                    sort: true
                },
                 {
                    label: "Email",
                    name: "email",
                    sort: true
                },
                {
                    label: "Phone Number",
                    name: "phoneNumber"
                },
                {
                    label: "Subscribed",
                    name: "terms_conditions"
                },
               
                {
                    label: "Action",
                    name: "action"
                }
            ],
            Leads:[],
            selectedLeads: [],
            IsLeadLoading: false,
            ExcelColumns: [
                {
                    label: "Name",
                    field: "name"
                },
                {
                    label: "Phone Number",
                    field: "phoneNumber"
                },
                {
                    label: "Email",
                    field: "email"
                }
            ],
            searchLeads: "",
            config: {
                checkbox_rows: true,
                rows_selectable: false,
                pagination: false,
                pagination_info: false,
                num_of_visibile_pagination_buttons: 5,
                per_page: 10,
                per_page_options: [5, 10, 15, 20, 30],
                show_refresh_button: false,
                show_reset_button: false,
                card_mode: true,
                card_title: "",
                highlight_row_hover_color: "#f2f2f2",

                global_search: {
                    placeholder: "Enter custom Search text",
                    visibility: false,
                    case_sensitive: false,
                    showClearButton: false,
                    searchOnPressEnter: false,
                    searchDebounceRate: 1000
                }
            },
            pagination:[]
    }
},
computed:{

     ...mapGetters(["getQuizID"]),
     getLeadsDataTable() {

             return this.Leads.filter((item) => {
        return (this.searchLeads.length === 0 || item.name.toLowerCase().includes(this.searchLeads.toLowerCase()))  
       
        
      })
        }
},
methods:{
     movePagination(url) {
      if (url) {
        
        this.getLeadData(url)
      }
    },
     
 openSeeResultModal(value) {
            this.$refs.SeeResultIndexModal.OpenModal(value);
        },
        onExport() {
            this.$toasted.show("Export Button Clicked", {
                theme: "toasted-primary",
                position: "bottom-center",
                duration: 2000
            });
        },
        OnRowCheckBoxChanged(e) {
            this.selectedLeads = e.selected_items;
        },
      async  DeleteLeads() {
            if (this.selectedLeads.length) {
                let deleteLeadsId = [];

                for (let i = 0; i < this.selectedLeads.length; i++) {
                    deleteLeadsId.push(this.selectedLeads[i].id);
                }

                 try {
                let data = {
                    _token: this.Token,
                    ids:JSON.stringify(deleteLeadsId),
                    quizId : this.QuizID
                };
                const response = await axios.post(`/deleteUserLeads`, data
                );
                if (
                    response.status == 200 &&
                    response.data.status == "success"
                ) {
                    this.selectedLeads = []
                  this.getLeadData()
                }
            } catch (error) {
                if (error) {
                    this.$toasted.show("Error occured", {
                        theme: "toasted-primary",
                        position: "bottom-center",
                        duration: 2000
                    });
                }
                throw error;
            } 
                
            }
        },
        async getLeadData(url) {
            this.IsLeadLoading = true;
            try {
                let data = {
                    _token: this.Token,
                     quizId : this.QuizID
                };
                const response = await axios.post(url, data);
                if (
                    response.status == 200 &&
                    response.data.status == "success"
                ) {
                   
                  this.Leads = response.data.data.data
                  this.pagination = response.data.data.links;
                }
            } catch (error) {
                if (error) {
                    this.$toasted.show("Error occured", {
                        theme: "toasted-primary",
                        position: "bottom-center",
                        duration: 2000
                    });
                }
                throw error;
            } finally {
                this.IsLeadLoading = false;
            }
        },
},
mounted(){
     this.QuizID = this.getQuizID
     this.getLeadData(`/loadUserLeads`)
}
}
</script>

<style scoped>
.LeadTableInput {
    border: 0.8px solid #b3afb6;
    box-sizing: border-box;
    border-radius: 6px;
}
.LeadTableInput input {
    background: transparent;
    border: none;
    outline: none;
}
.LeadTableInput input:focus {
    border: none;
    outline: none;
}
</style>